//this is the partners page
import React from "react";
import { partners } from "../../Constants/partners";
import Footer from "../Footer/Footer";
import "./Partners.css";
import { useTranslation } from "react-i18next";

export default function Partners() {
  const { t } = useTranslation();
  return (
    <div>
      <section className="partners" style={{ marginTop: "100px" }}>
        <h3 className="title-partners">{t("global.our_partners")}</h3>

        <div className="title-partners row">
          {partners.map((partner, key) => (
            <div key={key} className="col-md-12">
              <a href={partner.site} target="_blank" rel="noreferrer">
                <img
                  className="partner-list"
                  style={{ maxWidth: "200px" }}
                  alt={partner.alt}
                  src={partner.logo}
                />
              </a>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}
