// Description: Header Component
import React, { useState } from "react";
import square from "../../assets/img/nama_ad_simple_square_250x250.svg";

import largeleaderboard from "../../assets/img/nama_ad_simple_horizintal_320X100.svg";

export default function Banner() {

	return (
		<>
			<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 align-content-center justify-content-center m-auto d-md-none d-lg-none d-xl-none">
				<a
					href="https://www.namaventures.com/"
					rel="noreferrer"
					target="_blank"
					className="d-flex"
				>
					<img src={square} alt="Nama Coders" className="m-auto square" />
				</a>
			</div>
			<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 align-content-center justify-content-center pb-2 d-none d-md-block d-lg-block d-xl-block">
				<a
					href="https://www.namaventures.com/"
					rel="noreferrer"
					target="_blank"
					className="d-flex"
				>
					<img
						src={largeleaderboard}
						alt="Nama Coders"
						className="m-auto leaderboard"
					/>
				</a>
				<br />
			</div>
		</>
	);
}
