import NamaLogo from "../assets/img/nama.png";

export const partners = [
  {
    name: "Name Ventures",
    logo: NamaLogo,
    logo2: NamaLogo,
    logo3: NamaLogo,
    site: "https://www.namaventures.com",
    alt: "Name Ventures",
  }
];
